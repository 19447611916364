
.headers[data-v-12355e70]{
  background: #FFFFFF;
  padding: 14px 12px;
}
.headers[data-v-12355e70] .el-button{
  height: 28px;
}
[data-v-12355e70] .el-button+.el-button {
  margin-left: 0px;
}
